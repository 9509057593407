// menu mobile
$(document).on("turbolinks:load", function () {
  $("body").on("click", ".bars-mobile", function () {
    $("#mySidebar").css("width", "300px");
    $(".main").css("right", "300px");
    $(".header").css("right", "300px");
    $(this).addClass("d-none");
    $(".bars-close").removeClass("d-none").addClass("d-block");
    $("body").css("overflow", "hidden");
    $(".layout_blur").css("display", "block");
    $(".sign_in").removeClass("d-flex").addClass("d-none");
  });
  $("body").on("click", ".bars-close, .layout_blur", function () {
    $("#mySidebar").css("width", "0");
    $(".main").css("right", "0");
    $(".header").css("right", "0");
    $(".bars-close").addClass("d-none");
    $(".bars-mobile").removeClass("d-none").addClass("d-block");
    $("body").css("overflow", "auto");
    $(".layout_blur").css("display", "none");
    $(".sign_in").removeClass("d-none").addClass("d-flex");
  });

  $("input[name=keyword_mobile]").on("blur", function () {
    window.location.href = Routes.jobs_path({
      keyword: $("input[name=keyword]").val(),
    });
    $(this).prop("disabled", false);
  });

});

$(window).on("scroll resize turbolinks:load", function () {
  if ($(".home-container .banner-search_content").length > 0) {
    var searchEl = $(".home-container .banner-search_content");
    if ($(window).width() < 576) {
      if (
        $(window).scrollTop() >
        searchEl.offset().top + searchEl.innerHeight() - 60
      ) {
        $(".header_logo").removeClass("d-block").addClass("d-none");
        $(".header_search").removeClass("d-none").addClass("d-flex");
      } else {
        $(".header_search").removeClass("d-flex").addClass("d-none");
        $(".header_logo").removeClass("d-none").addClass("d-block");
      }
      return true;
    }
    $(".header_search").removeClass("d-flex").addClass("d-none");
    $(".header_logo").removeClass("d-none").addClass("d-block");
  }
});
